.upgradeCard{
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #E2E4E9;
  background: #FFF;
  display: flex;
  gap: 5px;
  flex-direction: column;
}

.upgradePlan{
  display: flex;
  justify-content: space-between;
}
.planProgress {
  width: 100%;
  height: 8px; 
  background-color: #e0e0e0; 
  border-radius: 4px; 
  margin-top: 5px; 
}

.progressBar {
  height: 100%; 
  background-color: #000000;
  border-radius: 4px;
}