:root {
  --font-inter: 'Inter', sans-serif;
  --Black-1: #CFD3D4;
  --Black-2: #ABAFB1;
  --Black-3: #000000;
  --Black-5: #2B2F32;
  --Gray: #DDE2E5;
  --Primary: #5570F1;
  --white: #FFFFFF;
  --max-width: 1440px;
}

/* @media (prefers-color-scheme: dark) {
  :root {
    --background: #0a0a0a;
    --foreground: #ededed;
  }
} */

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  background: var(--white);
  color: var(--black-5);
  font-family: var(--font-inter);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  cursor: pointer;
  border: none;
}

button:disabled {
  opacity: 0.5;
  cursor: auto;
  pointer-events: none;
}

input:focus,
input:focus-visible {
  outline: inherit;
  /* border: inherit; */
}

/* typography */

/* colors */
.color-black-1 {
  color: var(--Black-1);
}

.color-black-2 {
  color: var(--Black-2);

}

.color-black-3 {
  color: var(--Black-3) !important;

}

.color-black-5 {
  color: var(--Black-5);

}

.color-gray {
  color: var(--Gray);

}

.color-primary {
  color: var(--Primary);

}

.color-white {
  color: var(--white);
}

/* colors */


.heading-1 {
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.heading-2 {
  font-family: var(--font-inter);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.heading-3 {
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.57px;
}

.heading-4 {
  color: var(--text-main-900, #0A0D14);
  font-family: var(--font-inter);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.14px;
}


.input-placeholder {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.input-label {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.button-text {
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.terms-text {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.text-1 {
  font-family: var(--font-inter);
  font-size: 29px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.text-2 {
  font-family: var(--font-inter);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.text-4 {
  color: var(--Gray-600, #475467);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.text-3 {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

/* font weight  */
.fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight: 700;
}

/* margin  */
/* margin top  */
.mt-50 {
  margin-top: 50px;
}

/* typography */

/* @media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
} */

.mainContainer.activateGrid {
  display: grid;
  grid-template-columns: 304px 1fr;
  height: 100vh;
}

.mainContainer {
  display: grid;
  grid-template-columns: 1fr;
  height: 100vh;
  background: #f9f9f9;
}

.mainContent {
  height: 100vh;
  overflow-y: auto;
  position: relative;

}

.container {
  padding: 94px 72px;
}

/* Button styles */
.button-white {
  display: flex;
  padding: 17px 90px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: #fff;
  border-radius: 12px;
  border: 1px solid #000;
}

.button-black {
  color: #ffff;
  padding: 17px 90px;
  gap: 10px;
  background-color: #000;
  border-radius: 12px;
}