.sidenavContainer{
    display: flex;
    flex-direction: column;
    height: 85vh;
    justify-content: space-between;
    margin-top: 39px;
  }

  .sideBar{
    background-color: #F6F7F9;
    padding: 20px;
     }
    