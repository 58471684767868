:root {
  --font-inter: 'Inter', sans-serif;
  --Black-1: #CFD3D4;
  --Black-2: #ABAFB1;
  --Black-3: #000000;
  --Black-5: #2B2F32;
  --Gray: #DDE2E5;
  --Primary: #5570F1;
  --white: #FFFFFF;
  --max-width: 1440px;
}

/* @media (prefers-color-scheme: dark) {
  :root {
    --background: #0a0a0a;
    --foreground: #ededed;
  }
} */

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  background: var(--white);
  color: var(--black-5);
  font-family: var(--font-inter);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  cursor: pointer;
  border: none;
}

button:disabled {
  opacity: 0.5;
  cursor: auto;
  pointer-events: none;
}

input:focus,
input:focus-visible {
  outline: inherit;
  /* border: inherit; */
}

/* typography */

/* colors */
.color-black-1 {
  color: var(--Black-1);
}

.color-black-2 {
  color: var(--Black-2);

}

.color-black-3 {
  color: var(--Black-3) !important;

}

.color-black-5 {
  color: var(--Black-5);

}

.color-gray {
  color: var(--Gray);

}

.color-primary {
  color: var(--Primary);

}

.color-white {
  color: var(--white);
}

/* colors */


.heading-1 {
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.heading-2 {
  font-family: var(--font-inter);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.heading-3 {
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.57px;
}

.heading-4 {
  color: var(--text-main-900, #0A0D14);
  font-family: var(--font-inter);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.14px;
}


.input-placeholder {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.input-label {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.button-text {
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.terms-text {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.text-1 {
  font-family: var(--font-inter);
  font-size: 29px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.text-2 {
  font-family: var(--font-inter);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.text-4 {
  color: var(--Gray-600, #475467);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.text-3 {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

/* font weight  */
.fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight: 700;
}

/* margin  */
/* margin top  */
.mt-50 {
  margin-top: 50px;
}

/* typography */

/* @media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
} */

.mainContainer.activateGrid {
  display: grid;
  grid-template-columns: 304px 1fr;
  height: 100vh;
}

.mainContainer {
  display: grid;
  grid-template-columns: 1fr;
  height: 100vh;
  background: #f9f9f9;
}

.mainContent {
  height: 100vh;
  overflow-y: auto;
  position: relative;

}

.container {
  padding: 94px 72px;
}

/* Button styles */
.button-white {
  display: flex;
  padding: 17px 90px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: #fff;
  border-radius: 12px;
  border: 1px solid #000;
}

.button-black {
  color: #ffff;
  padding: 17px 90px;
  gap: 10px;
  background-color: #000;
  border-radius: 12px;
}
/* cyrillic-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/55c55f0601d81cf3-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/26a46d62cd723877-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/97e0cb1ae144a2a9-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/581909926a08bbc8-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/df0a9ae256c0569c-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/6d93bde91c0c2823-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(/_next/static/media/a34f9d1faa5f3315-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: 'Inter Fallback';src: local("Arial");ascent-override: 90.49%;descent-override: 22.56%;line-gap-override: 0.00%;size-adjust: 107.06%
}.__className_d65c78 {font-family: 'Inter', 'Inter Fallback';font-style: normal
}.__variable_d65c78 {--font-inter: 'Inter', 'Inter Fallback'
}

.sidelinks_Sidelinks___R2nF{
    list-style: none;
}

.sidelinks_Sidelinks___R2nF li a{
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 10px 6px;
}
.sidelinks_linkActive__oj_JV{
    border-radius: 8px;
    background: #FFF;
}
.sidelinks_linkActive__oj_JV span{
    color: #000;
}
.upgradecard_upgradeCard__FntMJ{
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #E2E4E9;
  background: #FFF;
  display: flex;
  gap: 5px;
  flex-direction: column;
}

.upgradecard_upgradePlan__g34Bb{
  display: flex;
  justify-content: space-between;
}
.upgradecard_planProgress__o4P4r {
  width: 100%;
  height: 8px; 
  background-color: #e0e0e0; 
  border-radius: 4px; 
  margin-top: 5px; 
}

.upgradecard_progressBar__vFVoZ {
  height: 100%; 
  background-color: #000000;
  border-radius: 4px;
}
.sidenav_sidenavContainer__MKjs1{
    display: flex;
    flex-direction: column;
    height: 85vh;
    justify-content: space-between;
    margin-top: 39px;
  }

  .sidenav_sideBar__YdUn4{
    background-color: #F6F7F9;
    padding: 20px;
     }
    
