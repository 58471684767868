.Sidelinks{
    list-style: none;
}

.Sidelinks li a{
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 10px 6px;
}
.linkActive{
    border-radius: 8px;
    background: #FFF;
}
.linkActive span{
    color: #000;
}